<template>

<div id="table-survey">

  <Loading 
    v-if="loading"
    pixeles="150px"
  />

  <template v-if="!loading">  
    <div class="row">
      <div class="col-12 mt-3">
        <div class="table-container">
          <table 
            v-if="!loading && encuestas.length !== 0"
            class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col" class="text-center">Estado</th>
                <th scope="col" class="text-center">Segmentación</th>
                <th scope="col" class="text-center">Participación</th>
                <th scope="col" class="text-center">Fecha de termino</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(encuesta, i) in encuestas"
                :key="i">
                <td class="th th-nombre">
                  {{ encuesta.nombre_encuesta }} <br>
                  <small class="fst-italic color-light" v-if="encuesta.preguntas.length == 1"> {{ encuesta.preguntas.length }} Pregunta </small>
                  <small class="fst-italic color-light" v-if="encuesta.preguntas.length > 1"> {{ encuesta.preguntas.length }} Preguntas </small>
                </td>
                <td class="th th-estado text-start text-center">
                  <span 
                    v-if="encuesta.nombre_categoria == 'Activa'"
                    class="badge badge-custom badge-custom-green-light">
                    {{ encuesta.nombre_categoria }}
                  </span>
                  <span 
                    v-if="encuesta.nombre_categoria == 'Finalizada'"
                    class="badge badge-custom badge-custom-gray-light">
                    {{ encuesta.nombre_categoria }}
                  </span>
                  <span 
                    v-if="encuesta.nombre_categoria == 'Programada'"
                    class="badge badge-custom badge-custom-blue-light">
                    {{ encuesta.nombre_categoria }}
                  </span>        
                </td>
                <td class="th th-segmentacion text-center" v-if="encuesta.segmentaciones == null">
                  Transversal
                </td>
                <td class="th th-segmentacion text-center" v-else-if="encuesta.segmentaciones.length == 0">
                  Transversal
                </td>
                <td v-else class="th th-segmentacion text-center">
                  {{ nameSeg(encuesta.segmentaciones[0].id_segmentacion) }}
                </td>
                <td class="th th-participacion text-center" v-if="encuesta.estadisticas !== undefined && encuesta.preguntas.length > 0">
                  <div class="progress-container d-flex align-items-center justify-content-center" style="height:1em;">
                    <span style="font-size: 1.2em;"> {{ encuesta.estadisticas.porcentaje }}% </span>

                    <div class="progress mx-2" style="height: 10px;width: 60%;">
                      <div 
                        :style="`width: ${encuesta.estadisticas.porcentaje}%`"
                        class="progress-bar"
                        :class="encuesta.estadisticas.porcentaje == 100 ? 'bg-success' : ''">
                      </div>
                    </div>
                  </div>
                  <div class="text-muted"> 
                    <small>{{ encuesta.estadisticas.total_respuestas }}/{{ encuesta.estadisticas.total_usuarios }} usuarios</small>
                  </div>
                </td>

                <td v-else class="th th-participacion text-center">
                  <span class="badge badge-custom-small border color-gray">
                    <font-awesome-icon icon="square-arrow-up-right" class="color-main"/>
                    Encuesta externa
                  </span>
                </td>
                <td class="th th-fecha-termino text-center">
                  {{ dateFormatymd(encuesta.fecha_termino_encuesta) }}
                </td>
                <td class="th th-accion text-center">
                    <div 
                      class="dropdown dropdown-custom d-flex justify-content-center" 
                      @click="openOption(encuesta.id_encuesta)">
                      <button
                        :disabled="encuesta.tipo_encuesta.id_tipo_encuesta ==2" 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul 
                        class="dropdown-menu"
                        v-click-outside="outOption">
                        <li v-if="encuesta.preguntas.length > 0 && encuesta.nombre_categoria != 'Programada' && encuesta.estadisticas.total_respuestas > 0">
                          <a 
                            class="dropdown-item" 
                            @click="verDetalles(encuesta)"
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                            Ver detalle
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="editEncuesta(encuesta)"
                            v-if="encuesta.nombre_categoria == 'Programada'"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                            Editar
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="openQuestionDelete(encuesta)"
                            v-if="encuesta.estadisticas.total_respuestas  == 0 && encuesta.tipo_encuesta.id_tipo_encuesta!=2"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                            Eliminar
                          </a>
                        </li>
                      </ul>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        <SinResultados 
          msg="Aceptar" 
          custom-click 
          @click="nuevaBusqueda" 
          v-if="!loading && encuestas.length === 0"/>

        <div class="modal-encuestas" v-if="open_modal_detalle">
          <ModalEncuesta 
            :title="detail.titulo"
            :reporte="reporte"
            @closeDetailModal="closeDetailModal"
          />
        </div>


        <Status
          v-if="open_modal_status"
          :msg="modal_status_msg"
          :status="modal_status"
          @close="acceptStatus"
        />
        <Question
          v-if="open_question_modal"
          :msg="question_modal_msg"
          :hideCancel="false"
          @cancel="cancelQuestion"
          @accept="acceptQuestion"
        />

      </div>
      <div class="users-list__pagination" v-if="pagination.page_count > 0">
        <paginate
          v-model="pagination.actual_page"
          :page-count="paginas_encuestas.length"
          :page-range="pagination.page_range"
          :click-handler="pagination.click_handler"
          :prev-text="pagination.prev_text"
          :next-text="pagination.next_text"
          :container-class="pagination.container_class"
          :page-class="pagination.page_class"
        />
        </div>
      </div>

    </div>
  </template>
</div>

</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import ModalEncuesta from './ModalEncuesta.vue';
import { mapActions, mapState } from "vuex";
import Loading from "@/components/Loading.vue";
import SinResultados from "@/components/SinResultados.vue";

export default {
  components: { 
    Question, 
    Status, 
    ModalEncuesta,
    Loading,
    SinResultados
   },
  props: {
    encuestas: {
      type: Array,
    },
    paginas_encuestas: {
      type: Array,
    },
    estados: {
      type: Array,
    },
    loading: {
      type: Boolean,
      required: false
    }

  },
  data() {
    return {
      open_modal_detalle: false,
      option: "",
      open_question_modal: false,
      question_modal_msg: "",
      encuesta_to_delete: null,
      open_modal_status: false,
      detail: {
        titulo: "",
      },
      pagina_actual: "1",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.paginacion,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },
    };
  },
  computed: {
    ...mapState("encuestasModule", ["reporte"]),
  },
  methods: {
    ...mapActions("encuestasModule", ["getReporte", "deleteEncuesta", "getEncuestasList", "getDataGraficoEncuesta"]),
    nuevaBusqueda(){
      this.$emit("cleanFilter");
    } 
    ,
    // control de paginación
    paginacion(){
      let total = this.paginas_encuestas.length;
      if(this.pagination.actual_page <= total){
        this.pagina_actual = this.pagination.actual_page;
        this.getListaEncuestaP();
      }
    },

    // Se debe evaluar el método getListaEncuestaP, para extrar y dejar en view padre list.vue de encuesta
    async getListaEncuestaP(){
      this.$emit('update:loading', true);
      this.paginas_encuestas.forEach((data) => {
        if (data.pagina == this.pagina_actual) {
          let variables = {
            id_empresa: this.$ls.get("user").empresa[0].id_empresa,
            id_encuesta: data.reportes
          }
          this.getEncuestasList(variables);
        }
      });
      this.$emit('update:loading', false);
    },

    async acceptQuestion() {
      this.$emit('update:loading', true);

      const data_ = {
        eliminarEncuestaIdEncuesta: this.encuesta_to_delete.id_encuesta,
        eliminarEncuestaIdEmpresa: this.$ls.get("user").id_empresa_fk,
        eliminarEncuestaIdUsuario: this.$ls.get("user").id_usuario,
      }

      const resDelete = await this.deleteEncuesta(data_);
      if (resDelete) {
        this.$toast.open({
          message: "Tú encuesta ha sido eliminada correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "No se ha podido eliminar la encuesta, intenta nuevamente",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
      this.$emit('update:loading', false);
      this.open_question_modal = false;
    },

    async verDetalles(encuesta) {
      this.detail.titulo = encuesta.nombre_encuesta;
      const params = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_encuesta: encuesta.id_encuesta,
      };
      await this.getDataGraficoEncuesta(params);

      if (this.reporte.preguntas.length != 0) {
        this.open_modal_detalle = true;
      } else {
        this.modal_status = true;
        this.modal_status_msg = "No hay preguntas que graficar.";
      }
    },

    closeDetailModal() {
      this.open_modal_detalle = false;
    },

    //abrir opciones
    openOption(id) {
      setTimeout(() => {
        this.option = id;
      }, 10);
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    editEncuesta(encuesta) {
      this.$router.push({
        name: "encuestas-edit",
        params: { id: encuesta.id_encuesta },
      });
    },
    openQuestionDelete(encuesta) {
      this.encuesta_to_delete = encuesta;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estas seguro de eliminar esta encuesta?";
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },

    nameSeg(name){
      if(name == '1') return 'Gerencia';
      if(name == '2') return 'Lugar de trabajo';
      if(name == '3') return 'Sindicato';
      if(name == '4') return 'Genero';
      else return 'Transversal';
    },


  },
 
};
</script>
