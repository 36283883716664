<template>
  <section class="graph-survey__card">
    <p class="graph-survey__card-title">{{ data.questionTitle }}</p>
    <p class="graph-survey__card-subtite">{{ data.numResponses }} respuestas</p>
    <div class="graph-survey__wrapper">
      <div class="graph-survey__chart" id="chart">
        <apexchart
          type="pie"
          :options="data.chartOptions"
          :series="data.series"
        ></apexchart>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
*::v-deep .apexcharts-legend-series {
  text-align: left;
}
*::v-deep .apexcharts-legend-text {
  margin-left: 5px;
}
*::v-deep .apexcharts-legend {
  position: absolute;
  right: 0px;
  width: 65%;
  flex-wrap: wrap;
  height: 123px;
  top: 10%;
  @media  (min-width: 1440px) {
    top: 30%;
  }
  &::-webkit-scrollbar {
    width:  4px;
    height: 4px;
    border-radius: 10px;
  }
}
*::v-deep .apexcharts-inner {
  width: 30%;
  position: absolute;
  border-left-width: 0px;
}
</style>
