<template>

  <section id="filter-survey">
    
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title my-3">
          Crea o edita tu encuesta
        </h3>
      </div>
    </div>

    <div class="row mt-3 mt-xxl-0">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar encuesta"
                v-model="filter.search"
                ref="searchInput"
                @keyup.enter="filterSearch()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="estados" 
              v-model="filter.estado"
              label="nombre_categoria"
              :clearable="false"
              placeholder="Filtrar por estado">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">

        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ListTable 
          :loading.sync="loading"
          :encuestas="encuestas_list" 
          :paginas_encuestas="paginas_encuestas" 
          :estados="estados"
          @cleanFilter="cleanFilter"
        />
      </div>
    </div>
  </section>

</template>
<script>
  import moment from "moment";
  import SectionTabs from "../Section/SectionTabs.vue";
  import ListTable from "./ListTable.vue";
  import { mapActions, mapState } from "vuex";


  //FF
  import InputDate from "@/components/forms/InputDate.vue"

  export default {
    components: { 
      SectionTabs, 
      ListTable, 

      //FF
      InputDate   
    },
    data() {
      return {
        tab_component: [
          {
            name: "Gestiona",
            route: "encuestas-list",
          },
          {
            name: "Crear",
            route: "encuestas-add",
          },
        ],
        pagina_default: 1,

        //FF
        loading: true,
        filter: { 
          search: '',
          estado: ''
        },
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },
    computed: {
      ...mapState("encuestasModule", ["encuestas_list", "paginas_encuestas", "estados"]),
    },

    async created() {
      await this.getEstadosEncuestas();
      await this.getEncuestas();
    },
    methods: {
      ...mapActions("encuestasModule", ["getEstadosEncuestas", "getEncuestasPaginasCompleto"]),
      cleanFilter(){
        this.filter.search = '';
        this.filter.estado = '';
        
        this.$refs.searchInput.focus();
        this.getEncuestas();
      },
      // Obtener lista de encuestas
      async getEncuestas() {
        this.loading = true;
        let data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          nombre: this.filter.search,
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_fin: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          id_categoria: this.filter.estado !== '' ? this.filter.estado.id_categoria : '0',
          limite: "15"
        };
        let pagina = this.pagina_default;
        await this.getEncuestasPaginasCompleto({data_, pagina});
        this.loading = false;
      },
      // Filtro text
      filterSearch() {
        this.getEncuestas();
      },
      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      },
    },
    watch: {
      "filter.search"(value) {
        if(value === '') {
          this.getEncuestas();
        }
      },
      "filter.estado"() {
        this.getEncuestas();
      },
      "range.date_start"() {
        this.getEncuestas();
      },
      "range.date_end"() {
        this.getEncuestas();
      },
    }
  };
</script>
