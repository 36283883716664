<template>
<div :class="graph_data.length > 0 ? 'modal-encuestas__detalle py-3 px-5' : 'modal-encuestas__detalle-reduce py-3 px-5'">
  <div class="modal-encuestas__upside-wrapper">
    <h3 class="modal-encuestas__title">{{ title }}</h3>

    <font-awesome-icon
      class="modal-encuestas__close-icon"
      icon="times"
      @click="$emit('closeDetailModal')"
    ></font-awesome-icon>
  </div>
  <hr />
  <div class="modal-encuestas__graph-container" v-if="graph_data.length > 0">
    <GraphCard v-for="(data, i) in graph_data" :key="i" :data="data" />
  </div>
  <div class="modal-encuestas__buttons-wrapper">
    <export-json-excel
      :data="data_excel"
      :fields="fields_excel"
      :name="title"
      :beforeExport="getDataExcel"
      :afterExport="() => this.show_spinner = false"
      class="export-button"
    >
      <button @click="() => this.show_spinner = true" class="modal-encuestas__download-button">
        Descargar Excel
      </button>
    </export-json-excel>
    <button
      @click="$emit('closeDetailModal')"
      class="modal-encuestas__close-button"
    >
      Cerrar
    </button>
  </div>
  <Spinner v-if="show_spinner"/>
</div>
</template>

<script>
/* eslint-disable */
import "export-json-excel";
import GraphCard from "./GraphCard.vue";
import { mapActions } from "vuex";
import Spinner from "../Spinner.vue";

export default {
  components: { GraphCard, Spinner },
  props: {
    title: {
      type: String,
      required: true
    },
    reporte: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show_spinner: false,
      data_excel: [],
      fields_excel: [],
      graph_data: [],
      dataEncuesta: {},
      defaultChartOptions: { // viene sin .labels!
        chart: {
          type: "pie",
          fontFamily: "Product Sans Bold",
          width: "40%",
          dropShadow: { enabled: true, top: 0, left: 0, blur: 1, color: "#0072f7", opacity: 0.35 },
        },
        dataLabels: {
          style: { colors: ["#fff"], fontSize: "17px", fontWeight: "bold" },
          dropShadow: { top: 0, left: 0, blur: 1, color: "#0072f7", opacity: 0.45 },
        },
        legend: {
          show: true,
          fontSize: "17px",
          fontFamily: "Product Sans Regular",
          offsetX: 0,
          position: "right",
          labels: { colors: ["#999BA6"], useSeriesColors: false, },
          markers: { offsetX: 0, },
        },
      }

    }
  },

  created() {
    this.fillAnswers();
  },

  mounted() {
    this.loadDataEncuesta();
  },

  methods: {
    ...mapActions("encuestasModule", ["getDataEncuesta"]),

    async loadDataEncuesta() {
      const payload = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idEncuesta: this.reporte.id_encuesta,
      };
      const resDataEncuesta = await this.getDataEncuesta(payload);
      if (!resDataEncuesta) {
        this.toastGenericError();
      } else {
        this.dataEncuesta = resDataEncuesta;
      }
    },

    async getDataExcel() {
      if (Object.keys(this.dataEncuesta).length === 0) {
        await this.loadDataEncuesta();
      }

      const fields = [
        { title: "Pregunta", name: "pregunta" },
        { title: "Tipo Pregunta", name: "tipo_pregunta" },
        { title: "Respuesta", name: "respuesta" },
      ];
      const data_ = [];
      if (this.dataEncuesta.is_anonima) {
        fields.push(
          { title: "Cantidad de respuestas", name: "count_respuesta" },
        );

        const idsPreguntasDesarrollo = this.dataEncuesta.preguntas
          .filter(p => p.tipo_pregunta_nombre_pregunta_tipo === "Desarrollo")
          .map(p => p.id_pregunta);

        for (const pregunta_ of this.dataEncuesta.preguntas) {
            if (idsPreguntasDesarrollo.includes(pregunta.id_pregunta)) continue;
            const tipo_pregunta = this._getTipo(pregunta_.tipo_pregunta.id_pregunta_tipo, pregunta_.is_star);
            const pregunta = pregunta_.texto_pregunta;
            const _preguntaFromGraph = this.reporte.preguntas.find(p => p.id_pregunta === pregunta_.id_pregunta);

            for (const alternativa_ of pregunta_.alternativas) {
              const respuesta = tipo_pregunta === "Emoji" ? this._getNameEmoji(alternativa_.texto_alternativa) : alternativa_.texto_alternativa;
              const count_respuesta = _preguntaFromGraph.encuesta_pregunta_alternativas.find(alt => alt.id_alternativa === alternativa_.id_alternativa).total_respuestas;
              data_.push({ tipo_pregunta, pregunta, respuesta, count_respuesta });
            }
        }

        for (const userAnswers of this.dataEncuesta.respuestas) {
          for (const respuesta_ of userAnswers.respuestas) {
            if (!idsPreguntasDesarrollo.includes(pregunta.id_pregunta)) continue;
            const tipo_pregunta = this._getTipo(respuesta_.pregunta.tipo_pregunta.id_pregunta_tipo, respuesta_.pregunta.is_star);
            const pregunta = respuesta_.pregunta.texto_pregunta;
            const respuesta = tipo_pregunta === "Emoji" ? this._getNameEmoji(respuesta_.respuesta) : respuesta_.respuesta;
            const count_respuesta = 1;
            data_.push({ tipo_pregunta, pregunta, respuesta, count_respuesta });
          }
        }

      } else {
        fields.push(
          { title: "RUT", name: "rut" },
          { title: "Nombre", name: "nombre" },
          { title: "Apellido", name: "apellido" },
          { title: "Cargo", name: "cargo" },
          { title: "Gerencia", name: "gerencia" },
        );

        for (const userAnswers of this.dataEncuesta.respuestas) {
          const rut = userAnswers.usuario.rut_usuario + '-' + userAnswers.usuario.dv_usuario;
          const nombre = userAnswers.usuario.primer_nombre;
          const apellido = userAnswers.usuario.apellido_paterno;
          const cargo = userAnswers.usuario.cargo[0].nombre_cargo;
          const gerencia = userAnswers.usuario.gerencia[0].nombre_gerencia;

          for (const respuesta_ of userAnswers.respuestas) {
            const tipo_pregunta = this._getTipo(respuesta_.pregunta.tipo_pregunta.id_pregunta_tipo, respuesta_.pregunta.is_star);
            const pregunta = respuesta_.pregunta.texto_pregunta;
            const respuesta = tipo_pregunta === "Emoji" ? this._getNameEmoji(respuesta_.respuesta) : respuesta_.respuesta;
            data_.push({ rut, nombre, apellido, cargo, gerencia, tipo_pregunta, pregunta, respuesta });
          }
        }
      }
      this.fields_excel = fields;
      this.data_excel = data_;
    },

    fillAnswers() {
      // Pasa los datos graph
      this.graph_data = this.getChartsData();
    },

    // obtiene el objeto graph
    getChartsData() {
      const graph_data = [];
      for (const pregunta of this.reporte.preguntas) {
        // console.log("tipo_pregunta", pregunta);
        if (pregunta.id_pregunta_tipo_fk != 1) {
          const tipo_pregunta = this._getTipo(pregunta.id_pregunta_tipo_fk, pregunta.is_star);
          // console.log(pregunta);
          const urlPatternImg = /https?:\/\/(?:www\.)?\S+\.(?:jpg|jpeg|png|gif|bmp|webp|svg)(?:\?.*)?/i;
          const urlPatternVideo = /https?:\/\/(?:www\.)?\S+\.(?:mp4|avi|mov|mkv|flv|wmv)(?:\?.*)?/i;
          
          graph_data.push({
            questionTitle: pregunta.texto_pregunta,
            numResponses: +pregunta.total,
            series: pregunta.encuesta_pregunta_alternativas.map(alternativaObj => +alternativaObj.total_respuestas),
            chartOptions: Object.assign({ 
              labels: pregunta.encuesta_pregunta_alternativas.map(alternativaObj => { 
                
                
                if (tipo_pregunta === "Emoji"){ 
                  return '<img style="height: 70px" src=' + alternativaObj.texto_alternativa + ">";
                }
                if (urlPatternImg.test(alternativaObj.texto_alternativa)) 
                      return '<img style="height: 70px" src=' + alternativaObj.texto_alternativa + ">";
                
                if (urlPatternVideo.test(alternativaObj.texto_alternativa)) 
                      return '<video style="height: 70px"  src=' + alternativaObj.texto_alternativa + ">";
               
                else return alternativaObj.texto_alternativa;
                }) 
              }, 
              structuredClone(this.defaultChartOptions)
            )
          });
        }
        
      }
      
      return graph_data;
    },

    // Retorna el tipo de pregunta
    _getTipo(id_tipo, is_star=null) {
      if (id_tipo == "1") return "Desarrollo"; 
      else if (id_tipo == "2") return "Alternativa Única"; 
      else if (id_tipo == "3") return "Alternativa Múltiple"; 
      else if (id_tipo == "4" && is_star == true) return "Calificación"; 
      else if (id_tipo == "4" && is_star == false) return "Escala"; 
      else if (id_tipo == "5") return "Emoji"; //(antes "Satisfacción")
    },

    _getNameEmoji(url){
      if ( url == "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_wrong.png" ) {
        return "Enojado";
      } else if ( url == "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_doubt.png" ) {
        return "Dudoso";
      } else if ( url == "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_smiley.png" ) {
        return "Feliz";
      }
    },

  },

  
}
</script>